import { useGlobalContext } from '@/components/Context/hooks/useGlobalContext';
import { DynamicCardsGrid } from 'components/DynamicCardsGrid';
import type { CardDataType } from 'components/DynamicCardsGrid/types';
import type {
  GenericPage,
  Product,
  DynamicCardGrid as TDynamicCardGrid,
} from 'server/repository/hygraph/generated/graphqlTypes';
import { TrackingService } from 'service/tracking/trackingService';
import { ISharedTemplateProps } from 'templates/TemplateMapper/types';

type TAlgoliaItems = GenericPage | Product;

interface IDynamicCardGrids extends ISharedTemplateProps {
  templateProps: TDynamicCardGrid & {
    algoliaItems: TAlgoliaItems[];
  };
}

export const DynamicCardGrid = ({
  templateProps,
  templateIndex,
  primarySubBrandColor,
  difficultyCopy,
}: IDynamicCardGrids) => {
  const position = templateIndex ?? 0 + 1;
  const data = templateProps.algoliaItems;
  const { userToken } = useGlobalContext();

  /**
   * Used to fire the teaser event for recipe cards in generic grid authorable
   */
  const clickTeaserRecipeListContainer = () => {
    TrackingService.clickTeaserRecipeList({
      containerTitle: templateProps?.heading,
      containerPosition: position,
      containerType: 'grid',
    });
  };

  return (
    data && (
      <DynamicCardsGrid
        data={templateProps as CardDataType}
        cardData={data as any}
        copyDictionary={difficultyCopy}
        showCards={templateProps.showCards}
        trackingEvents={{
          clickCTA: clickTeaserRecipeListContainer,
          clickCard: (
            targetCategory: string,
            targetSubcategory: string,
            targetTitle: string,
            id: number,
            recipeDifficulty: string,
            recipeRating: string,
            recipeDurationTotal: string,
            filterCategory: string,
            filters: string,
            sorting: string,
          ) => {
            TrackingService.seeRecipeClick(
              targetCategory,
              targetSubcategory,
              targetTitle,
              id,
              recipeDifficulty,
              recipeRating,
              recipeDurationTotal,
              filterCategory,
              filters,
              sorting,
            );
            TrackingService.articlePageConversionAfterSearch(
              (window.dataLayer as Record<string, string>[]) || [],
              userToken,
            );
          },
        }}
        host={''} // TODO: needs to be fixed
        ctaColor={primarySubBrandColor}
      />
    )
  );
};
