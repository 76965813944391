import clsx from 'clsx';
import { useState } from 'react';
import { COLORS } from 'styles/constants';
import { Card } from '../Cards';
import { Grid, GridItem, GridRow, getCardGridColspan } from '../Grid';
import { LiquidButton } from '../LiquidButton';
import { Text as Paragraph, Title } from '../Typography';
import type { IDynamicGrid } from './types';
import { IGridLayout } from './types';

export const DynamicCardsGrid = ({
  data,
  cardData,
  copyDictionary,
  showCards = 10,
  ctaColor,
  trackingEvents,
  host,
}: IDynamicGrid) => {
  const cardNumber = showCards ?? 10 - 1;
  const [hideClass, setHideClass] = useState<string>(
    cardData && cardData?.length >= cardNumber ? '' : 'hidden',
  );
  let totalCardWidth = 0;
  let isOdd = false;
  const cardVariation = data?.cardVariation;

  const GridLayout = ({ item, index, totalCards }: IGridLayout) => {
    const columnWidth = getCardGridColspan(totalCards, index);
    totalCardWidth += columnWidth;
    const prevOdd = isOdd;
    if (totalCardWidth % 32 === 0) {
      isOdd = !isOdd;
    }
    const seeRecipeClick = (recipeData: any) => {
      // eslint-disable-next-line react/prop-types
      trackingEvents?.clickCard &&
        // eslint-disable-next-line react/prop-types
        trackingEvents?.clickCard(
          recipeData?.category || 'na',
          'na',
          recipeData?.title || 'na',
          recipeData?.id || 0,
          copyDictionary?.[recipeData?.information?.difficulty] || 'na',
          recipeData?.information?.rating || 'na',
          recipeData?.information?.preparationTime || recipeData?.information?.totalTime || 'na',
          'na',
          'na',
          'na',
        );
    };
    return (
      <Card
        data={item}
        copyDictionary={copyDictionary}
        showInfo
        cardVariation={cardVariation || 'recipe'}
        link={item.link}
        height="500px"
        // eslint-disable-next-line react/prop-types
        cardType={data?.cardType || 'medium'}
        trackingEvent={() => seeRecipeClick(item)}
        position={index + 1}
        isOdd={prevOdd}
      />
    );
  };

  const onClickHandler = () => {
    const cardContainer = document.querySelectorAll('.card-wrapper.hidden');
    if (cardContainer.length <= cardNumber) {
      setHideClass('hidden');
    }
    for (let i = 0; i <= cardNumber; i += 1) {
      cardContainer[i]?.classList.remove('hidden');
    }

    trackingEvents?.clickCTA && trackingEvents.clickCTA();
  };

  return (
    <section className="max-w-none">
      <Grid>
        <GridRow columns={16}>
          <GridItem colSpan={16}>
            <div className="text-center">
              <Paragraph tag="p" type="lead" className="mb-2.5">
                {data?.subHeading}
              </Paragraph>
              <Title className="mb-5" tag="h2" type="md">
                {data?.heading}
              </Title>
              <Paragraph className="mb-10" tag="p" type="lg">
                {data?.description}
              </Paragraph>
            </div>
          </GridItem>
        </GridRow>
        <GridRow columns={16} tabletBreakpoint>
          {data?.recipeCarouselMarkUp ? (
            <div itemScope itemType="https://schema.org/ItemList" style={{ display: 'none' }}>
              {cardData?.map((card, index) => (
                <div
                  key={card.id ?? card.link?.url}
                  itemProp="itemListElement"
                  itemScope
                  itemType="https://schema.org/ListItem"
                >
                  <span itemProp="position">{index + 1}</span>
                  <span itemProp="url">{`https://${host}${card.link?.url}`}</span>
                </div>
              ))}
            </div>
          ) : null}
          {cardData?.map((recipe: any, index: number) => (
            <GridItem
              colSpan={getCardGridColspan(cardData.length, index)}
              className={index <= cardNumber ? 'card-wrapper' : 'hidden card-wrapper'}
              key={recipe.id ?? `dynamic-card-${index}`}
            >
              <GridLayout item={recipe} index={index} totalCards={cardData.length} />
            </GridItem>
          ))}
        </GridRow>
      </Grid>
      {data?.gridCta?.displayName && (
        <Paragraph tag="div" type="lead" className={clsx('text-center', hideClass)}>
          <button type="button" onClick={onClickHandler}>
            <span className="sr-only">{data?.gridCta?.displayName}</span>
            <LiquidButton
              text={data?.gridCta?.displayName}
              height={50}
              width={200}
              color={ctaColor || COLORS.primary}
              textColor={COLORS.white}
            />
          </button>
        </Paragraph>
      )}
    </section>
  );
};
